<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-email-replyto', params: { accountId: this.$route.params.accountId } }">Email Reply-To</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ emailReplyto.mailbox }}@{{ emailReplyto.domain }}</h1>
                <p class="text-caption text-center">Email reply-to overview</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="emailReplyto">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Reply-To</p>
                    <p class="mb-0 pb-0">
                        {{ emailReplyto.mailbox }}@{{ emailReplyto.domain }}
                        <v-btn icon color="teal darken-2" @click="editEmailReplytoFrom">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Display name</p>
                    <p class="mb-0 pb-0">
                        {{ emailReplyto.label }}
                        <v-btn icon color="teal darken-2" @click="editEmailReplytoDisplayName">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                        </v-btn>
                    </p>

                    <p class="text-overline mb-0 mt-8">Email Replyto ID</p>
                    <p class="mb-0 pb-0">
                        {{ emailReplyto.id }}
                    </p>

                    <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailTemplateList.length > 0">
                            This email reply-to is referenced by {{ affectedEmailTemplateList.length }} templates.
                            <!-- TODO: show the affectedEmailTemplateList -->
                        </span>
                        <span v-if="affectedEmailTemplateList.length === 0">
                        This email reply-to is not currently being used by any email templates.
                        </span>
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Usage</p>
                    <p class="mb-0 pb-0">
                        {{ usage }}
                        <v-tooltip top>
                            <template #activator="{ on, attrs }">
                                <v-btn icon @click="checkEmailReplytoUsage" v-bind="attrs" v-on="on">
                                    <font-awesome-icon :icon="['far', 'sync-alt']"/>
                                </v-btn>
                            </template>
                            Refresh
                        </v-tooltip>
                    </p> -->

                    <!-- <template v-if="emailReplyto.website_id">
                    <p class="text-overline mb-0 mt-8">Website</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: emailReplyto.website_id } }">
                            <span v-if="website">{{ website.label }}</span>
                            <span v-if="!website">Website</span>
                        </router-link>
                    </p>
                    </template> -->

                    <!-- <p class="text-overline mb-0 mt-8">Security</p>
                    <p class="mb-0 pb-0">
                        <router-link :to="{ name: 'account-edit-form-access', params: { accountId: this.$route.params.accountId, emailReplytoId: this.$route.params.emailReplytoId } }">Access control</router-link>
                    </p> -->
                    <!-- <template v-if="isPermitServiceAdmin">
                    <p class="text-overline mb-0 mt-8">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                    <p class="mb-0 pb-0">
                    </p> -->
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>
                                Email Replyto Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.emailReplyto, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                </v-col>
            </v-row>
            <v-dialog v-model="editEmailReplytoFromDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email "from" address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The email "from" address is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <p>
                            The domain you use in this "from" address must be the same one
                            that was set up for anti-spam tools.
                        </p>

                        <v-row no-gutters align="center">
                            <v-text-field
                                ref="emailReplytoMailboxInput"
                                v-model="editableEmailReplytoMailbox"
                                label="Mailbox"
                                :rules="newEmailReplytoMailboxRules"
                                validate-on-blur
                                color="teal darken-2"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>
                            <!-- <div class="mb-3"><p>@</p></div> -->
                            <v-select outlined dense :items="editableEmailReplytoDomainChoices" v-model="editableEmailReplytoDomain" color="teal darken-2" label="Domain" required hint="A verified domain that you control">
                                <template #prepend>
                                    @
                                </template>
                                <!-- <template #append-outer>
                                    <v-btn icon color="teal darken-2" @click="onClickAddDomain">
                                        <font-awesome-icon :icon="['fas', 'plus-circle']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template> -->
                            </v-select>
                        </v-row>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailReplytoFrom" :disabled="!isEditEmailReplytoFromComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailReplytoFromDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="editEmailReplytoDisplayNameDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email display name</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The email display name is the first thing users will see.
                            You can change it at any time.
                        </p>
                        <v-text-field v-model="editableEmailReplytoDisplayName" label="Email display name"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailReplytoDisplayName" :disabled="!isEditEmailReplytoDisplayNameComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailReplytoDisplayNameDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <!-- <v-dialog v-model="editEmailReplytoReplyToDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="green--text">Edit the email reply-to address</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-col>
                        <p>
                            The email reply-to address is where responses will go when the user hits
                            the "reply" button in their email application.
                            You can change it at any time, but it will only affect future emails sent.
                            You should keep an prior addresses used to collect replies for a while
                            (until you see that it's not getting any replies for a period of time, such
                            as 90 days) before you delete the mailbox.
                        </p>
                        <v-text-field v-model="editableEmailReplytoReplyTo" label="Email reply-to address"></v-text-field>
                    </v-col>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="green white--text" @click="saveEditEmailReplytoReplyTo" :disabled="!isEditEmailReplytoReplyToComplete">
                        <span>Save</span>
                    </v-btn>
                    <v-btn text color="grey" @click="editEmailReplytoReplyToDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog> -->
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
// import EmailReplytoList from '@/components/account-dashboard/EmailReplytoList.vue';
// import { compact } from '@/sdk/input';
// import { randomText } from '@/sdk/random';

export default {
    components: {
        // EmailReplytoList,
    },
    data: () => ({
        affectedEmailTemplateList: [],

        error: null,
        account: null,
        emailReplyto: null,
        invite: null,
        // edit form title dialog
        editEmailReplytoFromDialog: false,
        editEmailReplytoDisplayNameDialog: false,
        editableEmailReplytoDisplayName: null,
        // editEmailReplytoReplyToDialog: false,
        // editableEmailReplytoReplyTo: null,

        editableEmailReplytoFrom: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.account !== null && this.emailReplyto !== null;
        },
        isEditEmailReplytoFromComplete() {
            return typeof this.editableEmailReplytoFrom === 'string' && this.editableEmailReplytoFrom.trim().length > 0;
        },
        isEditEmailReplytoDisplayNameComplete() {
            return typeof this.editableEmailReplytoDisplayName === 'string' && this.editableEmailReplytoDisplayName.trim().length > 0;
        },
        // isEditEmailReplytoReplyToComplete() {
        //     return typeof this.editableEmailReplytoReplyTo === 'string' && this.editableEmailReplytoReplyTo.trim().length > 0;
        // },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadEmailReplyto() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEmailReplyto: true });
                const response = await this.$client.account(this.$route.params.accountId).emailReplyto.get(this.$route.params.emailReplytoId);
                console.log(`loadEmailReplyto: response ${JSON.stringify(response)}`);
                if (response) {
                    this.emailReplyto = response;
                    // TODO: set the editable fields instead, like this.editableDisplayname = response.label ?? '';
                    this.emailReplyto.label ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load email reply-to');
                }
            } catch (err) {
                console.error('failed to load email reply-to', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEmailReplyto: false });
            }
        },
        async save(itemAttr) {
            try {
                this.error = false;
                this.$store.commit('loading', { saveEditEmailReplyto: true });
                // TODO: only send what changed -- check input and sequence for changes separately
                const response = await this.$client.account(this.$route.params.accountId).emailReplyto.edit({ id: this.$route.params.emailReplytoId }, itemAttr);
                console.log(`saveEditEmailReplyto: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    return true;
                }
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email reply-to' });
                return false;
            } catch (err) {
                console.error('failed to edit email reply-to', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit email reply-to' });
                return false;
            } finally {
                this.$store.commit('loading', { saveEditEmailReplyto: false });
            }
        },
        editEmailReplytoFrom() {
            this.editEmailReplytoFromDialog = true;
            this.editableEmailReplytoFrom = this.emailReplyto.from;
        },
        editEmailReplytoDisplayName() {
            this.editEmailReplytoDisplayNameDialog = true;
            this.editableEmailReplytoDisplayName = this.emailReplyto.label;
        },
        async saveEditEmailReplytoFrom() {
            const isEdited = await this.save({ from: this.editableEmailReplytoFrom });
            this.editEmailReplytoFromDialog = false;
            if (isEdited) {
                this.$set(this.emailReplyto, 'from', this.editableEmailReplytoFrom);
            }
        },
        async saveEditEmailReplytoDisplayName() {
            const isEdited = await this.save({ label: this.editableEmailReplytoDisplayName });
            this.editEmailReplytoDisplayNameDialog = false;
            if (isEdited) {
                this.$set(this.emailReplyto, 'label', this.editableEmailReplytoDisplayName);
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadEmailReplyto();
    },
};
</script>
